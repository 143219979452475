import Section from "@/components/Layout/Section";
import Image from "next/image";

const RoadmapSection = () => {
  return (
    <>
      <div className="container mx-auto my-28" id="roadmap">
        <div className="flex flex-col lg:flex-row justify-center">
          <div className="flex-1 pr-4 pl-4">
            <div className="roadmap-left">
              <div className="section-title mb-30">
                <h2 className="text-3xl lg:text-5xl">
                  Roadmap
                  {/* <span className="title-2">ROADMAP</span> SEASONS */}
                </h2>
              </div>
              <p className="mb-30 text-md mt-5">
                Our roadmap is as simple as it gets: buy Carlos, hodl Carlos,
                and get rich with Carlos!
              </p>
              <div className="flex justify-center mx-auto lg:mr-20 my-20 transition ease-in-out duration-150 hover:scale-105 shadow-lg shadow-gray-700 rounded-2xl">
                <Image
                  src="/assets/images/carlos/Carlos - The future king-min.png"
                  alt="Roadmap Image"
                  width={800}
                  height={450}
                  style={{ borderRadius: "16px" }}
                />
              </div>
              {/* <div className="roadmap-btn">
                <a className="inline-block align-middle text-center select-none border font-normal whitespace-no-wrap rounded py-1 px-3 leading-normal no-underline bg-gray-600 text-white hover:bg-gray-700" href="">
                  Roadmap
                </a>
              </div> */}
            </div>
          </div>
          <div className="flex-1 pr-4 pl-4 text-center flex flex-col justify-center">
            <div className="roadmap-steps-wrap flex flex-col gap-12">
              <div className="roadmap-steps-item bg-gradient-to-r from-slate-900 to-slate-700 rounded-2xl p-4 shadow-lg shadow-gray-700 transition ease-in-out duration-150 hover:scale-105">
                <h1 className="text-4xl font-bold uppercase">
                  Phase 1: Pre-sale
                </h1>
                <h2 className="text-xl">Buy Carlos</h2>
              </div>
              <div className="roadmap-steps-item bg-gradient-to-r from-slate-900 to-slate-700 rounded-2xl p-4 shadow-lg shadow-gray-700 transition ease-in-out duration-150 hover:scale-105">
                <h1 className="text-4xl font-bold uppercase">
                  Phase 2: Launch on UNISWAP
                </h1>
                <h2 className="text-xl">Buy more Carlos</h2>
              </div>
              <div className="roadmap-steps-item bg-gradient-to-r from-slate-900 to-slate-700 rounded-2xl p-4 shadow-lg shadow-gray-700 transition ease-in-out duration-150 hover:scale-105">
                <h1 className="text-4xl font-bold uppercase">
                  Phase 3: Dong out
                </h1>
                <h2 className="text-xl">Get rich with Carlos</h2>
              </div>
              <div className="roadmap-steps-item bg-gradient-to-r from-purple-500 to-purple-900 rounded-2xl p-4 shadow-lg shadow-gray-700 transition ease-in-out duration-150 hover:scale-105">
                <h1 className="text-4xl font-bold uppercase">
                  Phase 4: Unlimited Money
                </h1>
                <h2 className="text-xl"></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoadmapSection;
