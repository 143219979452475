import { BrowserProvider, Contract, Eip1193Provider, ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import ABI from "@/contracts/Carlos.json";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

const useToken = () => {
  const { chainId } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const [signer, setSigner] = useState<any>(null);
  
  useEffect(() => {
    const getSigner = async(): Promise<any> => {
      try {
        const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
        const s = await ethersProvider.getSigner();
        setSigner(s)
      } catch (e) {
        return null;
      }
    }

    if (walletProvider) {
      getSigner();
    }
    
  }, [walletProvider]);

  const tokenAddress: string = chainId ? ABI.networks[chainId]?.address : null;

  return useMemo(
    () => signer && tokenAddress && new Contract(tokenAddress, ABI.abi, signer),
    [signer, tokenAddress]
  );
};

export default useToken;