import Section from "@/components/Layout/Section";
import Image from "next/image";

const TokenomicsSection = () => {
  return (
    <>
      <Section id="tokenomics" className="p-120">
        <div className="text-white bg-black rounded-lg xl:flex justify-between">
          <div className="xl:w-7/12">
            <div className="section-title p-3 mb-12">
              <h2 className="text-3xl md:text-5xl xl:text-7xl text-center">Tokenomics</h2>
              <p className="text-sm sm:text-base"></p>
            </div>
            <div className="flex flex-col my-5 gap-3">
              <div className="flex w-full hover:scale-105 transition-all duration-300">
                <div className="relative bg-white flex items-center gap-4 font-bold w-full ps-5 p-4 rounded-lg border-orange-400 border-s-8">
                  <div
                    className="absolute top-0 left-0 h-full bg-orange-400 rounded-r-lg"
                    style={{ width: "90%" }}
                  ></div>
                  <div className="relative z-10 text-gray-800 text-xl sm:text-xl">
                    90%
                  </div>
                  <div className="relative z-10 text-black text-xs sm:text-base">
                    LP + Pre-Sale
                  </div>
                </div>
              </div>
              <div className="flex w-full hover:scale-105 transition-all duration-300">
                <div className="relative bg-white flex items-center gap-4 font-bold w-full ps-5 p-4 rounded-lg border-fuchsia-400 border-s-8">
                  <div
                    className="absolute top-0 left-0 h-full bg-fuchsia-400 rounded-r-lg"
                    style={{ width: "5%" }}
                  ></div>
                  <div className="relative z-10 text-gray-800 text-xl sm:text-xl">
                    5%
                  </div>
                  <div className="relative z-10 text-black text-xs sm:text-base">
                    Team (Locked for 12 months after launch)
                  </div>
                </div>
              </div>
              <div className="flex w-full hover:scale-105 transition-all duration-300">
                <div className="relative bg-white flex items-center gap-4 font-bold w-full ps-5 p-4 rounded-lg border-lightBlue-400 border-s-8">
                  <div
                    className="absolute top-0 left-0 h-full bg-lightBlue-400 rounded-r-lg"
                    style={{ width: "5%" }}
                  ></div>
                  <div className="relative z-10 text-gray-800 text-xl sm:text-xl">
                    5%
                  </div>
                  <div className="relative z-10 text-black text-xs sm:text-base">
                    Marketing & Partners
                  </div>
                </div>
              </div>
              {/* <div className="flex w-full p-3 sm:w-6/12">
                <div className="relative bg-white flex items-center gap-4 font-bold w-full ps-5 p-4 rounded-lg border-pink-400 border-s-8">
                  <div
                    className="absolute top-0 left-0 h-full bg-pink-400 rounded-r-lg"
                    style={{ width: "20%" }}
                  ></div>
                  <div className="relative z-10 text-pink-400 text-xl sm:text-xl">
                    20.00%
                  </div>
                  <div className="relative z-10 text-black text-xs sm:text-base">
                    Airdrop
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="xl:w-4/12 p-3 flex items-center justify-center">
            <Image
              src={"/assets/images/carloship.png"}
              height={400}
              width={400}
              alt="LOS"
            />
          </div>
        </div>
      </Section>
    </>
  );
};

export default TokenomicsSection;
