import { IContainer } from '@/interfaces/IContainer'

const Container = ({ children, className }: IContainer) => {
    return (
        <div
            className={`container p-4 mx-auto xl:px-0 ${className ? className : ""
                }`}>
            {children}
        </div>
    )
}

export default Container