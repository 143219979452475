"use client";
import BuyToken from "@/components/BuyToken";
import Image from "next/image";
import React, { useState } from "react";

const Hero = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="bg-image relative" id="home">
      <div className="arrowOne animation-blink animation-delay-500"></div>
      <div className="radial-banner hidden lg:block"></div>

      <div className="pt-20 lg:pt-60 text-center container mx-auto px-4">
        <div className="arrowTwo animation-blink animation-delay-1000"></div>
        <div className="arrowThree animation-blink animation-delay-2000"></div>
        <div className="arrowFour animation-blink animation-delay-3000"></div>
        <div className="arrowFive animation-blink animation-delay-4000"></div>
        <div className="arrowSix animation-blink animation-delay-5000"></div>
        <div className="arrowSeven animation-blink animation-delay-6000"></div>
        <div className="arrowEight animation-blink animation-delay-7000"></div>

        {/* <div className="flex flex-col lg:flex-row-reverse justify-center items-end w-full gap-10 lg:gap-20">
          <div className="flex-shrink-0 mx-auto lg:mx-0">
            <Image
              src="/assets/images/carlos/Carlos-removebg-preview-min-bubble.png"
              width={500}
              height={300}
              alt="Carlos"
            />
          </div>
          <div className="flex flex-col justify-start items-center lg:items-start flex-1">
            <h6 className="sub-title text-center lg:text-left">
              Carlos. Small Dog. Massive Dong. Don't stare at it. He hates it.{" "}
            </h6>
            <h2 className="title text-4xl lg:text-7xl font-bold mb-5 text-white text-center lg:text-left">
              CARLOS
              <span>
                <h3 className="title text-xl lg:text-2xl font-bold mt-5 text-white text-center lg:text-left">
                  Pronouns: Good / Boy
                </h3>
              </span>
            </h2>
            <p className="text-white text-lg font-normal mb-10 text-center lg:text-left">
              Welcome to the world of CARLOS, the dog memecoin that's here to
              fetch you some serious gains! It's time to let the do(n)g out!
            </p>
            <div className="w-full flex justify-center lg:justify-start">
              <BuyToken />
            </div>
          </div>
        </div> */}

        <div className="grid grid-cols-1 xl:grid-cols-2">
          <div className="flex flex-col justify-start items-center lg:items-start gap-4">
            <h6 className="uppercase text-[#4584f8] p-2 bg-gradient-to-r from-indigo-700 rounded-xl text-center lg:text-left">
              Small Dog. Massive Dong. Don't stare at it. He hates it.
            </h6>
            <h2 className="title text-7xl font-bold mb-5 text-white text-center lg:text-left">
              CARLOS
              <span>
                <h3 className="title text-xl lg:text-2xl font-bold mt-5 text-white text-center lg:text-left">
                  Pronouns: Good / Boy
                </h3>
              </span>
            </h2>
            <p className="text-white text-lg font-normal mb-10 text-center lg:text-left">
              Welcome to the world of CARLOS, the dog memecoin that's here to
              fetch you some serious gains! It's time to let the do(n)g out!
            </p>
          </div>
          <BuyToken />
        </div>
      </div>
    </div>
  );
};

export default Hero;
