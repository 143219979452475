import { IBoxFull } from "@/interfaces/IBoxFull";
import Image from "next/image";

export default function BoxFull({ id, title = '', children }: IBoxFull) {
    return (
        <div className='mx-auto max-w-7xl pt-40 px-4 min-h-fit' id={id}>
            <div className="table-b bg-navyblue p-8 relative">
                {title && <h3 className="text-offwhite text-2xl">{title}</h3>}
                <div className="w-full mt-10 h-full golden-border">
                    {children}
                </div>
            </div>
            <Image src={'/assets/images/ellipse.svg'} alt="ellipse" width={2460} height={102} className="mt-6" />
        </div>
    )
}