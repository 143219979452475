"use client"
import { ReactNode, useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import useUsdtAllowance from '@/hooks/useUsdtAllowance'
import useBuyToken from '@/hooks/useBuyToken'
import { CountdownTimer } from './Countdown'
import ClaimContainer from './ClaimContainer'

const BuyToken = () => {
    const chain = 1;
    const { chainId, isConnected, address } = useWeb3ModalAccount()
    const { buyToken, getAcquirableAmount, claimStageTokens, loading, error, tokenData } = useBuyToken()
    const [amount, setAmount] = useState<number>(0)
    const [tokenAmount, setTokenAmount] = useState<bigint>(0n)
    const { allowance, requestUSDTAllowance } = useUsdtAllowance()
    const [stage, setStage] = useState<bigint>(tokenData.currentStageId || 1n)
    const [hasMounted, setHasMounted] = useState(false)
    const [selectedToken, setToken] = useState('eth')

    const correctNetwork = useMemo(() => {
        return chainId === chain
    }, [chainId])

    const buyTokens = async () => {
        if (amount <= 0) return
        await buyToken(selectedToken, amount);
    }

    const changeBuyableAmount = (amount: number) => {
        if (isNaN(amount) || amount == 0) {
            setAmount(0);
            setTokenAmount(0n);
            return;
        }
        setAmount(amount)
    }

    const addAllowance = async () => {
        requestUSDTAllowance(9999999999999n * (10n ** 6n))
    }

    const claimTokens = async () => {
        await claimStageTokens(stage)
    }

    useEffect(() => {
        setStage(tokenData.currentStageId);
    }, [tokenData.currentStageId])

    useEffect(() => {
        const update = async () => {
            const ret = await getAcquirableAmount(selectedToken, amount);
            setTokenAmount(ret);
        }

        if (amount) {
            update()
        }

    }, [amount, selectedToken])

    const { open } = useWeb3Modal()

    const today = new Date();
    const target = new Date(today)
    target.setDate(target.getDate() + 14)

    useEffect(() => {
        setHasMounted(true)
    }, [hasMounted])


    const changeToken = (token: string) => {
        setToken(token)
    }

    const tokenSoldPercent = () => {
        if (!tokenData.tokensToSell) return 0;

        // God knows why but here we remove the USDT decimals from our token decimals...
        const sold = Number(tokenData.tokensSold / (10n ** tokenData.decimals));
        const toSell = Number(tokenData.tokensToSell / (10n ** tokenData.decimals));

        return sold / toSell * 100;
    }

    return (
        <div className='text-center relative'>
            <Image src={'/assets/images/los_cartoon.webp'}
                height={150}
                width={150}
                alt="LOS" className="absolute xl:top-0 xl:-left-14 xl:-rotate-45 shadow-lg" />
            <div className="flex flex-col gap-3 bg-gradient-to-r font-bold from-slate-500 to-slate-800 text-center text-white shadow-xl rounded-xl rounded-b-none shadow-black p-4 w-6/12 mx-auto z-10 relative">
                BUY $LOS NOW!
            </div>
            <div className="bg-gradient-to-r from-violet-600 to-indigo-600 text-center text-white shadow-xl rounded-xl rounded-b-none shadow-black px-4 py-2 mx-12 md:mx-32 xl:mx-10 z-10 relative">
                <h1 className='uppercase text-3xl'> STAGE {tokenData.currentStageId.toString()}</h1>
            </div>
            <div className={`flex flex-col gap-3 bg-white text-center shadow-black shadow-xl rounded-xl p-6 relative z-20 text-black`}>
                <div>
                    {(isConnected) ?
                        <>
                            <h3 className='mb-2 font-bold'>1 LOS = ${tokenData.tokenPrice.toFixed(8)}</h3>
                            <h3 className='mb-2 font-bold'>{Number(tokenData.currentStageId) == tokenData.stages.length ? <>LAUNCH PRICE</> : <>NEXT STAGE</>} 1 LOS = ${tokenData.nextStageTokenPrice.toFixed(8)}</h3>

                            <div className="relative w-full">
                                <h3 className="italic text-sm">{(tokenData.tokensSold / (10n ** tokenData.decimals)).toLocaleString()} / {(tokenData.tokensToSell / (10n ** tokenData.decimals)).toLocaleString()} tokens sold</h3>
                            </div>

                            <div className="relative h-5 rounded-full overflow-hidden bg-gray-300 mt-4 mb-8 mx-auto">
                                <div className="absolute top-0 bottom-0 left-0 rounded-full bg-gradient-to-r from-pink-500 to-purple-500" style={{ 'width': tokenSoldPercent() + '%' }}>
                                </div>
                            </div>

                        </>
                        : ``}

                    <div>
                        <div className="grid sm:grid-cols-3 gap-2 3xl:gap-5 mb-5">
                            <button className={`border rounded-full py-2 px-7 w-full flex gap-4 items-center justify-center ${(selectedToken === 'eth') ? `bg-gray-800 text-white` : ``}`} onClick={() => changeToken('eth')}>
                                <Image src={'/assets/images/eth-logo.svg'}
                                    height={0}
                                    width={0}
                                    alt="ETH" className="bg-gray-100 text-black w-8 flex justify-center text-sm p-1 items-center h-8 rounded-full" />
                                ETH
                            </button>
                            <button className={`border rounded-full py-2 px-7 w-full flex gap-4 items-center justify-center ${(selectedToken === 'usdt') ? `bg-gray-800 text-white` : ``}`} onClick={() => changeToken('usdt')}>
                                <Image src={'/assets/images/usdt-logo.svg'}
                                    height={0}
                                    width={0}
                                    alt="USDT" className="bg-gray-100 text-black w-8 flex justify-center text-sm p-1 items-center h-8 rounded-full" />
                                USDT
                            </button>
                            <button className={`border rounded-full py-2 px-7 w-full flex gap-4 items-center justify-center ${(selectedToken === 'usdc') ? `bg-gray-800 text-white` : ``}`} onClick={() => changeToken('usdc')}>
                                <Image src={'/assets/images/usdc-logo.svg'}
                                    height={0}
                                    width={0}
                                    alt="USDC" className="bg-gray-100 text-black w-8 flex justify-center text-sm p-1 items-center h-8 rounded-full" />
                                USDC
                            </button>
                        </div>
                        <div>
                            <div className="flex flex-col sm:flex-row gap-2 3xl:gap-5">
                                <form className="w-full sm:w-6/12 flex flex-col gap-2 3xl:gap-5">
                                    <div className="flex justify-between">
                                        <span>Pay with <b>{selectedToken.toUpperCase()}</b></span>
                                    </div>
                                    <div className="flex gap-5 border rounded-full p-[7px]">
                                        <input type="number" step={selectedToken === 'eth' ? 0.0001 : 1} placeholder="0" disabled={(isConnected && correctNetwork) ? false : true} className={`${(isConnected && correctNetwork) ? `` : `disabled`} appearance-none outline-none w-100 px-6 bg-white`} min={selectedToken === 'eth' ? 0.0025 : 10} max={10000} onChange={(e) => changeBuyableAmount(e.target.valueAsNumber)} />
                                    </div>
                                </form>
                                <form className="w-full sm:w-6/12 flex flex-col gap-2 3xl:gap-5">
                                    <div className="flex justify-between">
                                        <p>Receive <b>LOS</b></p>
                                    </div>
                                    <div className="flex gap-5 border rounded-full p-[7px] w-full">

                                        <input type="number" disabled={true} placeholder="0" value={Number(tokenAmount)} className="appearance-none outline-none w-auto bg-white px-6" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className='my-4 group'>
                        {loading ? <span>Waiting for previous transaction to finish... Check your wallet.</span>
                            : (
                                <>
                                    {(isConnected && correctNetwork && !allowance && selectedToken !== 'eth') ? <button className='text-xl font-semibold py-4 z-30 px-6 mt-4 text-white text-center lg:px-12  rounded-xl transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100 bg-gradient-to-r from-indigo-700 to-purple-600' onClick={addAllowance}>Add Allowance</button> : <></>}
                                    {(isConnected && correctNetwork && ((allowance && selectedToken !== 'eth') || selectedToken === 'eth')) ? <button className={`text-xl font-semibold py-4 z-30 px-6 mt-4 text-white text-center lg:px-12  rounded-xl transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100 bg-gradient-to-r from-indigo-700 to-purple-600`} onClick={buyTokens}>Buy $LOS</button> : <></>}
                                    {!isConnected ? <button className='text-xl font-semibold py-4 z-30 px-6 mt-4 text-white text-center lg:px-12  rounded-xl transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100 bg-gradient-to-r from-indigo-700 to-purple-600' onClick={() => open()}>Connect Wallet</button> : <></>}
                                    {(isConnected && correctNetwork && tokenData.claimable.length > 0) ? <ClaimContainer stage={Number(stage)} loading={loading} setStage={setStage} tokenData={tokenData} claimTokens={claimTokens} /> : <></>}
                                </>
                            )
                        }
                    </div>

                    <div className={`absolute z-50 bottom-0 right-0 transition-all duration-500 ease-in-out flex justify-center items-center overflow-hidden opacity-0 ${error ? 'bottom-0 opacity-100' : '-bottom-20'}`}>
                        <div className={`transition-all duration-0 -mr-6 flex items-center justify-end ${error ? 'opacity-100' : 'opacity-0'}`}>
                            <div className="bg-rose-500 p-2 my-6 rounded-lg flex-1 text-white font-bold text-ellipsis overflow-hidden text-nowrap max-w-sm">
                                {error}
                            </div>
                            <div className="w-3 overflow-hidden ">
                                <div className="h-4 bg-rose-500 rotate-45 transform origin-top-left rounded-sm"></div>
                            </div>
                        </div>
                        <Image
                            src="/assets/images/carlos/CARLOS-min.png"
                            width={100}
                            height={100}
                            alt="Carlos"
                        />
                    </div>
                </div>

                <div className={`${(!hasMounted || (isConnected && !correctNetwork)) ? `backdrop-blur-md bg-white/30 absolute top-0 left-0 right-0 bottom-0 rounded-xl z-10` : ``}`}>

                </div>

                <div className={`${(isConnected && !correctNetwork) ? `block` : `hidden`} bg-transparent z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
                    <h1 className="text-rose-700 text-3xl w-full text-center">
                        <Image src='/assets/images/warning-sign.png' width={100} height={100} alt="" className='mx-auto text-center' />
                        Wrong network! Please switch to Ethereum.
                    </h1>
                </div>

                <div className={`${!hasMounted ? `block` : `hidden`} bg-transparent z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
                    <h1 className="text-black text-3xl w-full text-center">
                        <div
                            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                            role="status">
                            <span
                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >
                                Loading...
                            </span>
                        </div>
                    </h1>
                </div>

            </div >

            <Image src={'/assets/images/ellipse.svg'} alt="ellipse" width={0}
                height={0}
                sizes="20vw"
                style={{ width: '100%', height: 'auto' }} className="mt-2 animation-pulse" />
        </div >
    )
}

export default BuyToken