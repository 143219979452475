"use client";

import Image from "next/image";
import Link from "next/link";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

const faqdata = [
  {
    heading: "1. What is a Blockchain?",
    subheading:
      "A blockchain is a decentralized ledger of all transactions across a peer-to-peer network. Using this technology, participants can confirm transactions without a need for a central clearing authority. Potential applications can include fund transfers, settling trades, voting, and many other issues. Blockchain is a system of recording information in a way that makes it difficult / impossible to change, hack or cheat the system.",
  },
  {
    heading: "2. What is a Smart Contract?",
    subheading:
      "A smart contract is a self-executing contract with the terms of the agreement between buyer and seller being directly written into lines of code. The code and the agreements contained therein exist across a distributed, decentralised blockchain network. The code controls the execution, and transactions are trackable and irreversible.<br /> Smart contracts permit trusted transactions and agreements to be carried out among disparate, anonymous parties without the need for a central authority, legal system, or external enforcement mechanism.",
  },
  {
    heading: "3. What is a Liquidity Pool?",
    subheading:
      "A liquidity pool is a collection of funds locked in a smart contract. Liquidity pools are used to facilitate decentralised trading, lending, and many more functions we'll explore later.",
  },
  {
    heading: "4. What is Slippage?",
    subheading:
      "Slippage refers to the difference between the expected price of a trade and the price at which the trade is executed. Slippage can occur at any time but is most prevalent during periods of higher volatility when market orders are used. It can also occur when a large order is executed but there isn't enough volume at the chosen price to maintain the current bid/ask spread.",
  },
  {
    heading: "5. What are gas fees (GWEI)?",
    subheading:
      'Gas fees are payments made by users to compensate for the computing energy required to process and validate transactions on the blockchain. "Gas limit" refers to the maximum amount of gas (or energy) that you\'re willing to spend on a particular transaction.',
  },
  {
    heading: "6. What is a Wallet?",
    subheading: `A cryptocurrency wallet is an app or device that allows cryptocurrency users to store and retrieve their digital assets. As with conventional currency, you don't need a wallet to spend your cash, but it certainly helps to keep it all in one place. When a user acquires cryptocurrency, such as bitcoins, they can store it in a cryptocurrency wallet and from there use it to make transactions. 
      \n In short: Wallets are apps just like those you might run on a smartphone or computer. You can also buy a physical device that runs a wallet app.`,
  },
  {
    heading: "7. How long is the presale?",
    subheading: `The presale has no time frame. It ends once all Stages are sold. 
    Be quick.`,
  },
  {
    heading: "8. How many Stages or in the presale?",
    subheading: `5 Stages, with increasing token price in each stage.`,
  },
  {
    heading: "9. What's the launch price?",
    subheading: `The launch price will be approximately $0.00000533, 6.3 times more than the Stage 1 presale price.`,
  },
  {
    heading: "10. How to buy after presale?",
    subheading: `You can buy it here on the site or on Uniswap. Later on Centralised Exchanges that will be listed on our site.`,
  },
];

export default function Faq() {
  return (
    <div className="my-20 px-4" id="faq-section">
      <div className="section-title mb-12">
        <h2 className="text-center text-3xl lg:text-5xl font-bold text-offwhite mb-3">
          Frequently Asked Questions
        </h2>
      </div>

      <div className="mx-auto max-w-7xl">
        <div className="grid lg:grid-cols-2">
          {/* Column-1 */}
          <div>
            <div className="w-full px-4 pt-16">
              {faqdata.map((item, index) => (
                <div
                  className="mx-auto w-full max-w-5xl rounded-2xl bg-blue py-4 px-6 mb-5"
                  key={index}
                >
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <DisclosureButton className="flex w-full justify-between rounded-lg text-offwhite sm:px-4 sm:py-2 text-left text-base font-medium">
                          <span>{item.heading}</span>
                          <ChevronUpIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-purple-500`}
                          />
                        </DisclosureButton>

                        <DisclosurePanel className="px-4 pt-4 pb-2 text-base text-bluish font-normal opacity-50">
                          {item.heading === "10. How to buy after presale?" ? (
                            <>
                              You can buy it{" "}
                              <Link href="#buy-token">
                                <span className="text-purple-500 hover:underline cursor-pointer">
                                  HERE
                                </span>
                              </Link>{" "}
                              on the site or on Uniswap. Later on Centralised Exchanges that
                              will be listed on our site.
                            </>
                          ) : (
                            item.subheading
                          )}
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>
                </div>
              ))}
            </div>
          </div>

          {/* Column-2 */}
          <div className="flex justify-center items-center">
            <Image
              src={"/assets/images/carlos/Carlos_buff_02-min.png"}
              alt="faq-image"
              width={400}
              height={600}
              className="mx-auto hover:scale-105 transition-all duration-300"
            />
          </div>
        </div>
      </div>
    </div>
  );
}