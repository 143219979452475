"use client";
import TokenomicsSection from "@/components/Layout/Sections/TokenomicsSection";
import RoadmapSection from "@/components/Layout/Sections/RoadmapSection";
import Hero from "@/components/Layout/Sections/Hero";
import BoxFull from "@/components/Layout/BoxFull";
import Faq from "@/components/Layout/Faq";
import Simple from "@/components/Layout/Simple";
import Image from "next/image";
import BuyLos from "@/components/BuyLos";
import X from "@/components/X";
import Telegram from "@/components/Telegram";

const chain = 1337;

const today = new Date();
const target = new Date(today);
target.setDate(target.getDate() + 14);

export default function Home() {
  return (
    <main>
      <Hero />

      <BoxFull id="about">
        <div className="flex flex-wrap items-center mt-40 relative">
          <Image
            src="/assets/images/carlos/Carlos-removebg-preview-min-bubble2.png"
            width={400}
            height={400}
            alt="shape"
            className="absolute -top-80 md:-top-96 -left-22"
          />
          <div className="w-full text-center">
            <div className="mb-30 flex items-center gap-4 justify-center">
              <X />
              <div className="section-title">
                <h2 className="text-3xl lg:text-5xl">
                  A bit more about <span>CARLOS</span>
                </h2>
              </div>
              <Telegram />
            </div>
            <div className="about-des mt-6">
              <p>
                CARLOS is not just any dog; he's the top dog in the memecoin
                universe! He brings a new leash on life to the crypto world.
                With his wagging dong, CARLOS is here to make you laugh.
                Join the pack and let's take $LOS to the moon!
              </p>
            </div>

            <div className="flex justify-center my-8">
              <BuyLos />
            </div>
          </div>
        </div>
      </BoxFull>

      <Simple>
        <div className="relative">


          {/* <!-- Image for medium screens --> */}
          <div className="hidden md:block absolute bottom-full md:right-1/3 md:translate-x-1/2">
            <Image
              src="/assets/images/carlos/Carlos_Gold_Engrave.png"
              width={275}
              height={130}
              alt="Carlos"
            />
          </div>

          {/* Image for smaller screens */}
          <div className="flex justify-center mx-auto md:hidden absolute left-1/2 transform -translate-x-1/2 bottom-full">
            <Image
              src="/assets/images/carlos/Carlos_Gold_Engrave.png"
              width={250}
              height={120}
              alt="Carlos"
            />
          </div>

          <div className="container mx-auto mt-40 px-4 py-24">
            <div className="flex flex-col items-center justify-center hover:scale-110 transition-all duration-500">
              <div className="bg-gradient-to-r from-violet-600 to-indigo-600 bg-opacity-60 rounded-xl py-10 px-4 xl:p-10 pointer-events-none">
                <h2 className="text-center section-title text-4xl mb-4 uppercase">
                  TOTAL SUPPLY
                </h2>
                <p className="text-2xl rounded-full bg-violet-600 md:text-4xl bg-opacity-80 w-min p-4 text-center font-bold text-offwhite shadow-md">
                  100.000.000.000
                </p>
              </div>
            </div>
          </div>
        </div>
      </Simple>

      {/* THIS IS NEEDED AFTER THE PRE-SALE */}

      {/* <Boxes
          boxes={[
            {
              subheading:
                "Download and set up a crypto wallet (like MetaMask).",
              heading: "",
              imgSrc: "",
            },
            {
              subheading:
                "Buy some ETH on your favorite exchange and send it to your wallet.",
              heading: "",
              imgSrc: "",
            },
            {
              subheading: "Head over to Uniswap and connect your wallet.",
              heading: "",
              imgSrc: "",
            },
            {
              subheading: "Swap your ETH for Carlos (ticker: CARLOS).",
              heading: "",
              imgSrc: "",
            },
            {
              subheading:
                "Sit back, relax, and watch Carlos fetch you some gains!",
              heading: "",
              imgSrc: "",
            },
          ]}
        /> */}

      {/* THIS IS FOR PRE-SALE ONLY, CHANGE IT BACK TO THE ABOVE ONCE PRE-SALE IS FINISHED */}
      <BoxFull id="presale-section">
        <div className="flex flex-wrap items-center relative mb-12">
          <div className="flex justify-center w-full my-10">
            <div className="flex flex-col items-center w-full mx-auto gap-10 lg:gap-20">
              <div className="flex flex-col items-center w-full">
                <div className="section-title mb-12">
                  <h2 className="text-5xl xl:text-7xl text-center">
                    Pre-sale
                  </h2>
                </div>
                <div className="about-btn">
                  <BuyLos />
                </div>
                {/* <div className="w-full h-64 md:h-96 lg:h-128 mb-16 relative overflow-hidden">
                <Image
                  src="/assets/images/carlos/489A3B5D-0489-42E2-9514-9F9A088615E2-min.png" // Replace with your image path
                  alt="Pre-sale Image"
                  layout="fill"
                  objectFit="contain"
                />
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </BoxFull>

      <RoadmapSection />

      <TokenomicsSection />

      {/* THIS IS FOR PRE-SALE ONLY, CHANGE IT BACK TO THE ABOVE ONCE PRE-SALE IS FINISHED */}
      {/* <BoxFull id="presale-section2">
          <div className="flex flex-wrap items-center relative mb-12 w-full">
            <div className="flex justify-center w-full my-10">
              <div className="flex flex-col items-center w-full mx-auto gap-10 lg:gap-20">
                <div className="flex flex-col items-center w-full">
                  <div className="flex flex-col gap-3 bg-gradient-to-r from-violet-600 to-indigo-600 text-center text-white shadow-xl rounded-xl shadow-black p-4 w-full mx-auto z-10 relative mb-16">
                    <CountdownTimer
                      targetDate={target.toISOString()}
                      labels={["days", "hours", "minutes", "seconds"]}
                    />
                  </div>
                  <div className="about-btn">
                    <BuyLos />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BoxFull> */}

      <div className="simple-bg relative">
        <div className="simpleone"></div>
        <div className="simpletwo"></div>
        <div className="simplethree"></div>
        <div className="simplefour"></div>
        <div className="simplefive"></div>

        {/* {children} */}
        <div className="flex flex-wrap items-center relative my-12 w-full">
          <div className="flex justify-center w-full my-20">
            <div className="flex flex-col items-center w-full mx-auto gap-10 lg:gap-20">
              <div className="flex flex-col items-center w-full xl:w-auto px-4">
                {/* <div className="w-full flex flex-col gap-3 bg-gradient-to-r from-violet-600 to-indigo-600 text-center text-white shadow-xl rounded-xl shadow-black p-4 mx-auto z-10 relative mb-16">
                    <CountdownTimer
                      targetDate={target.toISOString()}
                      labels={["days", "hours", "minutes", "seconds"]}
                    />
                  </div> */}
                <div className="about-btn">
                  <BuyLos />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="simplesix"></div>
        <div className="simpleseven"></div>
        <div className="simpleeight"></div>
        <div className="simplenine"></div>
        <div className="simpleten"></div>
      </div>

      <Faq />
    </main>
  );
}
