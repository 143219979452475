import { useEffect, useState } from "react";
import useTokenSale from "./contracts/useTokenSale";
import useToken from "./contracts/useToken";
import { isError, parseEther } from "ethers";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { DecodedError, ErrorDecoder } from 'ethers-decode-error'
import '@/types/number';
import ABI from "@/contracts/Presale.json";

export interface tokenData {
  name: string;
  decimals: bigint;
  claimable: Array<bigint>;
  claimed: Array<bigint>;
  currentStageId: bigint;
  isClaimEnabled: Array<boolean>;
  tokensToSell: bigint;
  tokensSold: bigint;
  usdtHardCap: bigint;
  amountRaised: bigint;
  tokenPrice: number;
  nextStageTokenPrice: number;
  stages: Array<number>,
}

const useBuyToken = () => {
  const tokenContract = useToken();
  const tokenSaleContract = useTokenSale();
  const errorDecoder = ErrorDecoder.create([ABI.abi])

  const { chainId, address } = useWeb3ModalAccount()

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null)
  const [tokenData, setTokenData] = useState<tokenData>({
    name: '',
    decimals: 0n,
    claimable: [0n],
    claimed: [0n],
    currentStageId: 1n,
    isClaimEnabled: [false],
    tokensToSell: 0n,
    tokensSold: 0n,
    usdtHardCap: 0n,
    amountRaised: 0n,
    tokenPrice: 0,
    nextStageTokenPrice: 0,
    stages: [],
  });

  const buyTokenWithUSDT = async (amount: bigint): Promise<boolean> => {
    if (!tokenSaleContract || !tokenContract) return false;

    setLoading(true);

    let result = false;
    try {
      const buy = await tokenSaleContract.buyWithUSDT(amount);
      await buy.wait();
      await getTokenData();
      result = true;

    } catch (e) {
      const decodedError = await errorDecoder.decode(e)
      setError(decodedError.reason)

      setTimeout(() => {
        setError(null)
      }, 3000);
    } finally {
      setLoading(false);
    }

    return result;
  };

  const buyTokenWithUSDC = async (amount: bigint): Promise<boolean> => {
    if (!tokenSaleContract || !tokenContract) return false;

    setLoading(true);

    let result = false;

    try {
      const buy = await tokenSaleContract.buyWithUSDC(amount);
      await buy.wait();
      await getTokenData();
      result = true;

    } catch (e) {
      const decodedError = await errorDecoder.decode(e)
      setError(decodedError.reason)

      setTimeout(() => {
        setError(null)
      }, 3000);
    } finally {
      setLoading(false);
    }

    return result;
  };

  const buyTokenWithETH = async (amount: number): Promise<boolean> => {
    if (!tokenSaleContract || !tokenContract) return false;

    setLoading(true);

    let result = false;

    try {
      const decimals = amount.countDecimals();
      if (decimals > 0) {
        amount = Math.round(amount * (10 ** decimals));
      }
      // We are sending wei, and if input < 1, we need to remove as many decimal units
      // as there were in the input

      const ethAmount = BigInt(amount * (10 ** (18 - decimals)))

      const buy = await tokenSaleContract.buyWithEth({ value: ethAmount });
      await buy.wait();
      await getTokenData();
      result = true;

    } catch (e) {
      const decodedError = await errorDecoder.decode(e)
      setError(decodedError.reason)

      setTimeout(() => {
        setError(null)
      }, 3000);
    } finally {
      setLoading(false);
    }

    return result;
  };

  const claimStageTokens = async (stageId: bigint) => {
    if (!tokenSaleContract || !tokenContract) return;

    setLoading(true);
    try {
      const claim = await tokenSaleContract.claimAmount(stageId);
      await claim.wait();
      await getTokenData();

    } catch (e) {
      const decodedError = await errorDecoder.decode(e)
      setError(decodedError.reason)

      setTimeout(() => {
        setError(null)
      }, 3000);
    } finally {
      setLoading(false);
    }
  }

  const getTokenAmountFromUSDT = async (amount: number) => {
    if (!tokenSaleContract || !tokenContract) return;

    try {
      return await tokenSaleContract.usdtToTokens(await tokenSaleContract.currentSale(), amount);

    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  const getTokenAmountFromETH = async (amount: bigint) => {
    if (!tokenSaleContract || !tokenContract) return;

    try {
      return await tokenSaleContract.ethToTokens(await tokenSaleContract.currentSale(), amount);

    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  const getAcquirableAmount = async (token: string, amount: number) => {
    const saleId = await tokenSaleContract.currentSale();

    if (token == 'eth') {
      const decimals = amount.countDecimals();
      if (decimals > 0) {
        amount = Math.round(amount * (10 ** decimals));
      }
      // We are sending wei, and if input < 1, we need to remove as many decimal units
      // as there were in the input

      const ret: bigint = await tokenSaleContract.ethToTokens(saleId, BigInt(amount * (10 ** (18 - decimals))))

      return ret / BigInt(10 ** 18);
    }


    return await tokenSaleContract.usdtBuyHelper(saleId, amount);
  }

  const buyToken = async (token: string, amount: number) => {
    setError(null);
    let result = false;

    switch (token) {
      case 'usdt':
        result = await buyTokenWithUSDT(BigInt(amount) * (10n ** 6n))
        break
      case 'usdc':
        result = await buyTokenWithUSDC(BigInt(amount) * (10n ** 6n))
        break
      default:
        result = await buyTokenWithETH(amount)
        break;
    }

    if (result) {
      const usdtAmount = await currencyToUSDT(token, amount);
      const sold = Math.round(Number(tokenData.tokensSold / (10n ** tokenData.decimals)) / 1_000_000);
      const total = Math.round(Number(tokenData.tokensToSell / (10n ** tokenData.decimals)) / 1_000_000);
  
      await fetch(`/api`, {
        method: 'POST',
        next: { revalidate: 60 },
        body: JSON.stringify({
          paid: amount,
          stage: Number(tokenData.currentStageId),
          paidWith: token.toUpperCase(),
          usdtAmount: Number(usdtAmount),
          tokenAmount: Number(await getAcquirableAmount(token, amount)),
          tokenPrice: tokenData.tokenPrice.toFixed(8),
          nextStagePrice: tokenData.nextStageTokenPrice.toFixed(8),
          progress: `${sold.toLocaleString()}m / ${total.toLocaleString()}m`
        })
      })
    }

  }

  const currencyToUSDT = async (token: string, amount: number) => {
    if (token.toUpperCase() !== 'ETH') {
      return amount;
    }

    const ethPrice = await tokenSaleContract.getLatestPrice();

    return (Number(ethPrice) * amount / 10**18).toFixed(6);
  }

  const getTokenData = async () => {
    const name: string = await tokenContract?.symbol();
    const decimals: bigint = await tokenContract?.decimals();
    const saleId = await tokenSaleContract.currentSale();
    const ret = await tokenSaleContract.presale(saleId);

    const claimData = await Promise.all(Array.from({ length: Number(saleId) }, (v, i) => BigInt(i + 1)).map(async (id) => {
      return await tokenSaleContract.userClaimData(address, id);
    }));

    const presaleData = await Promise.all(Array.from({ length: Number(saleId) }, (v, i) => BigInt(i + 1)).map(async (id) => {
      const { isEnableClaim } = await tokenSaleContract.presale(id);
      return isEnableClaim;
    }));

    setTokenData({
      name: name,
      decimals: decimals,
      claimable: claimData.map((c) => c.claimAbleAmount),
      claimed: claimData.map((c) => c.claimedAmount),
      currentStageId: saleId,
      isClaimEnabled: presaleData,
      tokensToSell: ret['tokensToSell'],
      tokensSold: ret['Sold'],
      usdtHardCap: ret['UsdtHardcap'],
      amountRaised: ret['amountRaised'],
      tokenPrice: 1 / Number(BigInt(ret['price']) / (10n ** decimals)),
      nextStageTokenPrice: 1 / Number(BigInt(ret['nextStagePrice']) / (10n ** decimals)),
      stages: [1, 2, 3, 4, 5],
    });
  };

  useEffect(() => {
    if (!tokenSaleContract || !tokenContract) return;
    let mounted = true;


    if (mounted) {
      getTokenData();
    }

    return () => {
      mounted = false;
    };
  }, [tokenSaleContract, tokenContract, address, chainId]);

  return { buyTokenWithUSDT, buyTokenWithUSDC, buyTokenWithETH, buyToken, getTokenAmountFromUSDT, getTokenAmountFromETH, getAcquirableAmount, claimStageTokens, loading, error, tokenData };
};

export default useBuyToken;