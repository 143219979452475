import { tokenData } from "@/hooks/useBuyToken"
import { Dispatch } from "react"

const ClaimContainer = ({ tokenData, setStage, claimTokens, loading, stage }: { tokenData: tokenData, setStage: Dispatch<bigint>, claimTokens: any, loading: boolean, stage: number }) => {
    const totalClaimable = tokenData.claimable.reduce((sum, c) => {
        return BigInt(sum) + (c / BigInt(10 ** 18));
    }, 0n);

    const totalClaimed = tokenData.claimed.reduce((sum, c) => {
        return BigInt(sum) + (c / BigInt(10 ** 18));
    }, 0n);

    const hasAnyStageToClaimFrom = () => {
        return tokenData.stages.filter((s, k) => canClaimFromStage(k)).length > 0;
    }

    const handleChange = (event) => {
        setStage(event.target.value);
    }

    const canClaimFromStage = (stageIndex: number) => tokenData.isClaimEnabled[stageIndex] && tokenData.claimable[stageIndex] > 0n;

    return (
        <>
            <hr className="mb-4 mt-10" />
            {totalClaimable ? <p>You have <span className="font-bold bg-gradient-to-r from-indigo-700 to-purple-600 text-transparent bg-clip-text">{totalClaimable.toLocaleString()}</span> tokens to claim in total </p> : <></>}

            <div className='flex flex-col'>
                {hasAnyStageToClaimFrom() ?
                    (<>
                        <label htmlFor="stage" className="text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select stage</label>
                        <select id="stage" value={stage} onChange={handleChange} className="border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value={-1}>Select stage</option>
                            {
                                tokenData.stages.map((s, k) => (
                                    <option value={s.toString()} key={s}>Stage {s.toString()}</option>
                                ))
                            }
                        </select>
                    </>) : <></>
                }

                {
                    canClaimFromStage(stage - 1) ?
                        <div className="text-center mt-8">
                            Claim&nbsp;
                            <span className="font-bold bg-gradient-to-r from-indigo-700 to-purple-600 text-transparent bg-clip-text">{(tokenData.claimable[stage - 1] / (10n ** tokenData.decimals)).toLocaleString()}</span>
                            &nbsp;tokens from stage {stage.toString()}
                        </div>
                        :
                        <>
                            {
                                tokenData.claimed[stage - 1] > 0n && Number(tokenData.currentStageId) != tokenData.stages.length
                                    ?
                                    <div className="text-center mt-8">
                                        You've already claimed&nbsp;
                                        <span className="font-bold bg-gradient-to-r from-indigo-700 to-purple-600 text-transparent bg-clip-text">{(tokenData.claimed[stage - 1] / (10n ** tokenData.decimals)).toLocaleString()}</span>
                                        &nbsp;tokens from stage {stage.toString()}
                                    </div>
                                    : <></>
                            }
                        </>
                }
                <>
                    {
                        totalClaimed > 0n ? <>Total tokens claimed by you {totalClaimed.toLocaleString()}</> : <></>
                    }
                </>
                {canClaimFromStage(stage - 1) && !loading ?
                    (
                        <button disabled={loading} className={`text-xl font-semibold py-4 z-30 px-6 mt-4 text-white text-center lg:px-12 rounded-xl transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100 bg-gradient-to-r from-slate-300 to-slate-500`} onClick={claimTokens}>CLAIM MY TOKENS</button>
                    ) :
                    <></>
                }
            </div>
        </>
    )
}

export default ClaimContainer