import { useEffect, useState } from "react";
import useUsdt from "./contracts/useUsdt";
import useTokenSale from "./contracts/useTokenSale";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";

const useUsdtAllowance = () => {
  const usdtContract = useUsdt();
  const tokenSaleContract = useTokenSale();
  const [allowance, setAllowance] = useState<bigint>(0n);
  const [balance, setBalance] = useState<bigint>(0n);
  const { chainId, address } = useWeb3ModalAccount()

  const requestUSDTAllowance = async (amount: bigint) => {
    if (!tokenSaleContract || !usdtContract) return;
    
    try {
      if (allowance > 0) {
        await usdtContract.approve(tokenSaleContract?.target, 0n);
      }
      await usdtContract.approve(tokenSaleContract?.target, amount);
      setAllowance(BigInt(amount));
    } catch (error) {
      console.error(error);
    }
  }

  const getUSDTBalance = async () => {
    try {
      const response = await usdtContract.balanceOf(address);
      setBalance(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!tokenSaleContract || !usdtContract) return;
    let mounted = true;

    const getAllowance = async () => {
      try {
        const response = await usdtContract.allowance(address, tokenSaleContract?.target);
        setAllowance(response);
      } catch (error) {
        console.error(error);
      }
    };

    const getBalance = async () => {
      try {
        const response = await usdtContract.balanceOf(address);
        setBalance(response);
      } catch (error) {
        console.error(error);
      }
    };

    if (mounted) {
      getAllowance();
      getBalance();
    }

    return () => {
      mounted = false;
    };
  }, [usdtContract, address, tokenSaleContract, chainId]);

  return { allowance, balance, requestUSDTAllowance, getUSDTBalance };
};

export default useUsdtAllowance;