import Link from "next/link";

const BuyLos = () => {
    return (
        <Link
            className="
                        text-xl
                        font-semibold
                        py-4
                        z-30
                        px-6
                        text-white text-center
                        lg:px-12 
                        rounded-xl
                        transition-all duration-500
                        bg-size-200 bg-pos-0 hover:bg-pos-100
                        bg-gradient-to-r from-indigo-700 to-purple-600
                        "
            href="#buy-token"
        >
            Buy $LOS
        </Link>
    )
}

export default BuyLos;