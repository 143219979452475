import Container from '@/components/Layout/Container'
import { ISection } from '@/interfaces/ISection'

const Section = ({ id, children, className, container = true, containerClassName }: ISection) => {
    return (
        <section className={`${id}-section ${className}`} id={id}>
            {container ?
                <Container className={containerClassName}>
                    {children}
                </Container>
                : children}
        </section>
    )
}

export default Section